exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-art-tsx": () => import("./../../../src/pages/art.tsx" /* webpackChunkName: "component---src-pages-art-tsx" */),
  "component---src-pages-blog-archive-tsx": () => import("./../../../src/pages/blog/archive.tsx" /* webpackChunkName: "component---src-pages-blog-archive-tsx" */),
  "component---src-pages-blog-tags-tsx": () => import("./../../../src/pages/blog/tags.tsx" /* webpackChunkName: "component---src-pages-blog-tags-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-astronave-privacy-policy-tsx": () => import("./../../../src/pages/projects/astronave/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-projects-astronave-privacy-policy-tsx" */),
  "component---src-pages-projects-chicio-coding-privacy-policy-tsx": () => import("./../../../src/pages/projects/chicio-coding/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-projects-chicio-coding-privacy-policy-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-tag-tsx": () => import("./../../../src/templates/tag.tsx" /* webpackChunkName: "component---src-templates-tag-tsx" */)
}

